import { message } from 'antd';
import axios from 'axios';
import { getToken } from './storage';

const api = axios.create({
  baseURL: process.env.API || 'https://api.dev-blush-platform.com',
  timeout: process.env.AXIOS_TIMEOUT || 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});
let push = true;
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (error) => {
    if (error.response.data.statusCode === 403 && push) {
      message.error('閲覧のみで設定されております。', () => {
        push = true;
      });
      push = false;
    }
    return Promise.reject(error);
  }
);
const set = (token) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

set(getToken());

export { api, set };
