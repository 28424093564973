import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import image from '../../helpers/image';
import Logo from '../../../assets/img/logo.png';
import routes from '../../pages/routes';
import UserName from './UserName';

const Header = () => {
  const { pathname } = useLocation();
  const currentRoute = routes.find((route) => matchPath(pathname, route));

  const auth = currentRoute?.auth;

  return (
    <div className="sticky header-sticky">
      {!auth ? (
        <div className="header head-shadow pl__30">
          <div className="row h-100">
            <div className="col-lg-6 col-md-6">
              <div className="d-flex block-head-left h-100 align-items-center">
                <Link to="/" className="block-1">
                  <div className="block-item-middle d-inline-block text-center">
                    <img src={image.concat('', 'logo.png')} alt="logo" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header_other d-flex align-items-center">
          <Link to="/dashboard" className="logo">
            <img src={Logo} alt="logo" />
          </Link>
          <div className="box-header-username">
            <UserName />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
