import loadable from '@loadable/component';

const routes = [
  {
    path: '/error',
    exact: true,
    component: loadable(() => import('./Error')),
  },
  {
    path: '/dashboard',
    exact: true,
    auth: true,
    indexItem: 0,
    component: loadable(() => import('./Home')),
  },
  {
    path: '/sign-in',
    exact: true,
    component: loadable(() => import('./SignIn')),
  },
  {
    path: '/forgot-password',
    exact: true,
    component: loadable(() => import('./ForgotPassword/ForgotPassword')),
  },
  {
    path: '/reset-password',
    exact: true,
    component: loadable(() => import('./ForgotPassword/ResetPassword')),
  },
  {
    path: '/counselor',
    exact: true,
    auth: true,
    indexItem: 2,
    component: loadable(() => import('./ListCounselor')),
  },
  {
    path: '/counselor/:id',
    exact: true,
    auth: true,
    indexItem: 2,
    component: loadable(() => import('./CounselorDetailed')),
  },
  {
    path: '/users',
    exact: true,
    auth: true,
    indexItem: 3,
    component: loadable(() => import('./Users/ListUser')),
  },
  {
    path: '/users/:id',
    exact: true,
    auth: true,
    indexItem: 3,
    component: loadable(() => import('./Users/DetailUser')),
  },
  {
    path: '/coupon',
    exact: true,
    auth: true,
    indexItem: 7,
    component: loadable(() => import('./Coupon/ListCoupon')),
  },
  {
    path: '/coupon/detail/:id',
    exact: true,
    auth: true,
    indexItem: 7,
    component: loadable(() => import('./Coupon/DetailCoupon')),
  },
  {
    path: '/coupon/edit/:id',
    exact: true,
    auth: true,
    indexItem: 7,
    component: loadable(() => import('./Coupon/EditCoupon')),
  },
  {
    path: '/coupon/create',
    exact: true,
    auth: true,
    indexItem: 7,
    component: loadable(() => import('./Coupon/CreateCounpon')),
  },
  {
    path: '/banner',
    exact: true,
    auth: true,
    indexItem: 9,
    component: loadable(() => import('./Banner/ListBanner')),
  },
  {
    path: '/banner/create',
    exact: true,
    auth: true,
    indexItem: 9,
    component: loadable(() => import('./Banner/CreateBanner')),
  },
  {
    path: '/banner/detail/:id',
    exact: true,
    auth: true,
    indexItem: 9,

    component: loadable(() => import('./Banner/DetailBanner')),
  },
  {
    path: '/banner/edit/:id',
    exact: true,
    auth: true,
    indexItem: 9,
    component: loadable(() => import('./Banner/EditBanner')),
  },
  {
    path: '/product',
    exact: true,
    auth: true,
    indexItem: 8,
    component: loadable(() => import('./Product/ListProduct')),
  },
  {
    path: '/product/detail/:id',
    exact: true,
    auth: true,
    indexItem: 8,
    component: loadable(() => import('./Product/DetailProduct')),
  },
  {
    path: '/product/edit/:id',
    exact: true,
    auth: true,
    indexItem: 8,
    component: loadable(() => import('./Product/EditProduct')),
  },
  {
    path: '/product/create',
    exact: true,
    auth: true,
    indexItem: 8,
    component: loadable(() => import('./Product/CreateProduct')),
  },
  {
    path: '/sales',
    exact: true,
    auth: true,
    indexItem: 10,
    component: loadable(() => import('./Sales/SaleIndex')),
  },
  {
    path: '/master-data',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/Index')),
  },
  {
    path: '/master-data/careers',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/CareerIndex')),
  },
  {
    path: '/master-data/brands',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/BrandIndex')),
  },
  {
    path: '/master-data/skills',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/SkillIndex')),
  },
  {
    path: '/master-data/worries',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/WorryIndex')),
  },
  {
    path: '/master-data/setting',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/Setting')),
  },
  {
    path: '/master-data/setting/edit',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/EditSetting')),
  },
  {
    path: '/master-data/template-email',
    exact: true,
    auth: true,
    indexItem: 11,
    component: loadable(() => import('./MasterData/TemplateEmail')),
  },
  {
    path: '/authority',
    exact: true,
    auth: true,
    indexItem: 12,
    component: loadable(() => import('./Authority/AuthorityIndex')),
  },
  {
    path: '/authority/create',
    exact: true,
    auth: true,
    indexItem: 12,
    component: loadable(() => import('./Authority/CreateAuthority')),
  },
  {
    path: '/authority/update/:id',
    exact: true,
    auth: true,
    indexItem: 12,
    component: loadable(() => import('./Authority/UpdateAuthority')),
  },
  {
    path: '/reservation',
    exact: true,
    auth: true,
    indexItem: 4,
    component: loadable(() => import('./Reservation')),
  },
  {
    path: '/staff',
    exact: true,
    auth: true,
    indexItem: 1,
    component: loadable(() => import('./Staff/StaffIndex')),
  },
  {
    path: '/staff/new-staff',
    exact: true,
    auth: true,
    indexItem: 1,
    component: loadable(() => import('./Staff/StaffCreate')),
  },
  {
    path: '/staff/:staffId',
    exact: true,
    auth: true,
    indexItem: 1,
    component: loadable(() => import('./Staff/StaffDetail')),
  },
  {
    path: '/staff/:staffId/updated',
    exact: true,
    auth: true,
    indexItem: 1,
    component: loadable(() => import('./Staff/StaffUpdate')),
  },
  {
    path: '/reservation/:id',
    exact: true,
    auth: true,
    indexItem: 4,
    component: loadable(() => import('./Reservation/DetailReservation')),
  },
  {
    path: '/schedule',
    exact: true,
    auth: true,
    indexItem: 6,
    component: loadable(() => import('./Schedule')),
  },
  {
    path: '/requested',
    exact: true,
    auth: true,
    indexItem: 5,
    component: loadable(() => import('./Requested')),
  },
];
export default routes;
