import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import IconMenu1 from '../../../assets/img/dashboard.svg';
import IconMenuDashboardActive from '../../../assets/img/icon_dashboard_active.svg';
import IconMenu2 from '../../../assets/img/staff.svg';
import IconMenuStaffActive from '../../../assets/img/icon_stuff_active.svg';
import IconMenu3Active from '../../../assets/img/icon_menu_3_active.png';
import IconMenu4 from '../../../assets/img/user.svg';
import IconMenuUserActive from '../../../assets/img/icon_user_active.svg';
import IconMenu5 from '../../../assets/img/reservation.svg';
import IconMenuReservation from '../../../assets/img/reservation_active.svg';
import IconMenu6 from '../../../assets/img/schedule.svg';
import IconMenu7 from '../../../assets/img/coupon.svg';
import IconMenuCouponActive from '../../../assets/img/icon_coupon_active.svg';
import IconMenu8 from '../../../assets/img/sales.svg';
import IconSaleActive from '../../../assets/img/sale_active.svg';
import IconMenu9 from '../../../assets/img/master-date.svg';
import IconMenu9Active from '../../../assets/img/icon_menu_9_active.svg';
import IconMenu10 from '../../../assets/img/authority.svg';
import IconMenu10Active from '../../../assets/img/icon_menu_10_active.svg';
import IconScheduleActive from '../../../assets/img/schedule-icon-active.svg';
import IconRequested from '../../../assets/img/requested.svg';
import IconRequestedActive from '../../../assets/img/requested_active.svg';

import { deleteCookie, getCookie, USER_TOKEN } from '../../helpers/storage';

export default function SidebarMenu({ indexItem }) {
  const data = [
    {
      title: 'Dashboard',
      icon: IconMenu1,
      link: '/dashboard',
      iconActive: IconMenuDashboardActive,
    },
    {
      title: 'Staff',
      icon: IconMenu2,
      link: '/staff',
      iconActive: IconMenuStaffActive,
    },
    {
      title: 'Counselor',
      iconActive: IconMenu3Active,
      link: '/counselor',
      icon: IconMenu4,
    },
    {
      title: 'User',
      icon: IconMenu4,
      link: '/users',
      iconActive: IconMenuUserActive,
    },
    {
      title: 'Reservation',
      icon: IconMenu5,
      link: '/reservation',
      iconActive: IconMenuReservation,
    },
    {
      title: 'Requested',
      icon: IconRequested,
      link: '/requested',
      iconActive: IconRequestedActive,
    },
    {
      title: 'Schedule',
      icon: IconMenu6,
      link: '/schedule',
      iconActive: IconScheduleActive,
    },
    {
      title: 'Coupon',
      icon: IconMenu7,
      link: '/coupon',
      iconActive: IconMenuCouponActive,
    },
    {
      title: 'Cosme',
      icon: IconMenu7,
      link: '/product',
      iconActive: IconMenuCouponActive,
    },
    {
      title: 'Banner',
      icon: IconMenu7,
      link: '/banner',
      iconActive: IconMenuCouponActive,
    },
    {
      title: 'Sales',
      icon: IconMenu8,
      link: '/sales',
      iconActive: IconSaleActive,
    },
    {
      title: 'Master data',
      icon: IconMenu9,
      iconActive: IconMenu9Active,
      link: '/master-data',
    },
    {
      title: 'Authority',
      icon: IconMenu10,
      iconActive: IconMenu10Active,
      link: '/authority',
    },
  ];
  const history = useHistory();
  const handleSignOut = () => {
    const myCookie = getCookie(USER_TOKEN);
    if ((window.localStorage.getItem(USER_TOKEN) || myCookie) !== '') {
      window.localStorage.removeItem(USER_TOKEN);
      deleteCookie();
      history.push('/sign-in');
    }
  };
  return (
    <div className="sidebar_menu">
      <div className="list_menu">
        <ul className="m-0 p-0">
          {data.map((item, index) => (
            <li
              key={index}
              className={`d-flex align-items-center zi__1 position-relative ${
                indexItem === index ? 'active' : ''
              }`}
            >
              <Link
                to={{ pathname: item.link, state: { fresh: true } }}
                className="d-flex align-items-center"
              >
                <div className="icon">
                  <img
                    src={indexItem === index ? item.iconActive : item.icon}
                    alt="icon"
                  />
                </div>
                <span>{item.title}</span>
              </Link>
            </li>
          ))}
          <li className="d-flex align-items-center zi__1 position-relative">
            <Link
              to="#"
              onClick={handleSignOut}
              className="d-flex align-items-center"
            >
              <span>ログアウト</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
