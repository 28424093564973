import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import Root from './Root';

ReactDOM.render(
  <ConfigProvider locale={jaJP}>
    <Root />
  </ConfigProvider>,
  document.getElementById('app')
);
