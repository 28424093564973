export const USER_TOKEN = 'ADMIN_TOKEN';

export const setItem = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const setToken = (value) => {
  setItem(USER_TOKEN, value);
};

export const clearToken = () => setToken('');

const setCookie = (cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires='.concat(d.toUTCString());
  document.cookie = `${USER_TOKEN} = ${cvalue} ; ${expires};path=/;`;
};
const getCookie = () => {
  const name = USER_TOKEN.concat('=');
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const deleteCookie = () => {
  const name = USER_TOKEN.concat('=');
  document.cookie = name.concat(
    '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
  );
  return '';
};

export const getItem = (key) => {
  const value = window.localStorage.getItem(key) || getCookie();
  return value === null ? '' : value;
};

export const getToken = () => getItem(USER_TOKEN);

export { setCookie, getCookie, deleteCookie };
