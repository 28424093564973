/* eslint import/no-extraneous-dependencies: 0 */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'swiper/swiper.scss';
import 'bootstrap/scss/bootstrap.scss';
import '../scss/app.scss';
import implementsArr from './util/arrImplement';

import Routes from './router/Index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000,
    },
  },
});

implementsArr();
const Root = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <div>
        <Routes />
      </div>
    </BrowserRouter>
  </QueryClientProvider>
);

export default Root;
