import React, { useEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { message } from 'antd';
import Layout from '../components/common/Layout';
import PrivateRoute from './PrivateRouter';
import PublicRoute from './PublicRouter';
import routes from '../pages/routes';
import { api } from '../helpers/axios';

export default function Index() {
  const { pathname } = useLocation();
  const history = useHistory();
  const [permissions, setPermissions] = useState([]);
  const getMe = async () => {
    const meRes = await api.get('/api/admin/me');
    setPermissions(meRes?.data?.data?.permission);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    if (pathname.match(/^(\/)$/)) {
      history.push('/dashboard');
    }
    const minWidth = window.matchMedia('(min-width: 1921px)');
    const { width } = screen;
    if (minWidth.matches) {
      document.documentElement.style.setProperty('--window-width', width - 320);
    }
    getMe();
  }, [pathname]);

  useEffect(() => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path === pathname) {
        if (permissions[(routes[i]?.indexItem || 0) - 1]?.permission === 0) {
          message.error('アクセス権がありません');
          history.push('/dashboard');
        }
        break;
      }
    }
  }, [pathname]);

  return (
    <Layout>
      <Switch>
        {routes.map((route, i) => {
          if (route.auth) {
            return <PrivateRoute key={i} {...route} />;
          }
          return <PublicRoute key={i} {...route} />;
        })}
      </Switch>
    </Layout>
  );
}
