/* eslint react/prop-types: 0, react/jsx-props-no-spreading: 0 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SidebarMenu from '../components/common/SidebarMenu';
import { getToken } from '../helpers/storage';

const PrivateRoute = ({ component: Component, indexItem, ...rest }) => {
  const detectToken = getToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        detectToken ? (
          <div className="d-flex">
            <SidebarMenu indexItem={indexItem} />
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
