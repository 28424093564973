/* eslint-disable no-extend-native */
const implementsArr = () => {
  Array.range = (n) => Array.apply(this, Array(n)).map((x, i) => i);

  Object.defineProperty(Array.prototype, 'chunk', {
    value(n) {
      return Array.range(Math.ceil(this.length / n)).map((x, i) =>
        this.slice(i * n, i * n + n)
      );
    },
  });
};
export default implementsArr;
