import React from 'react';

import { getItem } from '../../helpers/storage';

export default function UserName() {
  return (
    <div className="username d-flex align-items-center justify-content-end">
      <div className="icon" />
      <div className="u_name">
        <h4>{getItem('admin_full_name')}　</h4>
      </div>
    </div>
  );
}
